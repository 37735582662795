var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-5 pt-sm-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "text-center" }, [
            _vm._m(0),
            _c("h4", { staticClass: "text-uppercase mt-4" }, [
              _vm._v("Desculpe, alguma coisa deu errado com a sua requisição.")
            ]),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v(
                " Por favor, entre em contato com a equipe de suporte para obter ajuda. "
              )
            ]),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "router-link",
                  { staticClass: "btn btn-primary", attrs: { to: "/" } },
                  [_vm._v("Voltar a tela inicial")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-sm-4" }, [
          _c("div", { staticClass: "error-img" }, [
            _c("img", {
              staticClass: "img-fluid mx-auto d-block",
              attrs: { src: require("@/assets/images/500-error.png"), alt: "" }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }